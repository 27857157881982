import { useQuery } from '@tanstack/react-query'
import backend from '../services/backend'
import { useMemo } from 'react'
import { SUBSCRIPTION_OFFER_1_D } from '@olystic/common/subscription.mjs'

export function useSubscription() {
  const subscriptionQuery = useQuery(['subscription'], async () => {
    const res = await backend.subscriptions.getSubscription()
    return res.data
  })

  const { canCreateBase, canCreateGroup, isFreeSubscription } = useMemo(() => {
    let canCreateBase, canCreateGroup, isFreeSubscription
    if (subscriptionQuery.data) {
      const options = subscriptionQuery.data.level.options
      canCreateBase = options.rh.multiple
      canCreateGroup = options.rh.groupe
      isFreeSubscription =
        subscriptionQuery.data.level.title === SUBSCRIPTION_OFFER_1_D
    }
    return { canCreateBase, canCreateGroup, isFreeSubscription }
  }, [subscriptionQuery])

  return {
    subscriptionQuery,
    canCreateBase,
    canCreateGroup,
    isFreeSubscription,
  }
}
