import React from 'react'
import { Button, Form } from 'react-bootstrap'
import Select from 'react-select'
import './rhMappingModal.scss'
import { useTranslation } from 'react-i18next'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import { Scrollbar } from '@olystic/design_system_3'
import { useSubscription } from '../../../../hooks/useSubscription'

const sortedCategories = ['Matricule', 'E-Mail', 'Nom', 'Prénom']

function Mapping({
  columns,
  mapping,
  options,
  handleFilters,
  selectedCategories,
  onChangeSelectedCategories,
}) {
  const { t } = useTranslation()
  const { subscriptionQuery, isFreeSubscription } = useSubscription()

  let used = Object.values(mapping).map((o) => o.value)
  const availOptions = options.filter((o) => !used.includes(o.value))

  const maxSelections = 6

  const haveNoMatchMapping = columns.find(
    (entry) => !Object.keys(mapping).includes(entry[0]),
  )

  const sortedColumns = columns.sort((a, b) => {
    const indexA = sortedCategories.indexOf(a[0])
    const indexB = sortedCategories.indexOf(b[0])

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB
    }

    if (indexA === -1) return 1
    if (indexB === -1) return -1

    return 0
  })

  const canSelectMore = selectedCategories.length < maxSelections
  return (
    <div>
      <h4>{t('component.rhMapping.heading')}</h4>
      <p className='pb-3 text-black-50'>
        {t('component.rhMapping.description')}
      </p>
      {isFreeSubscription && (
        <p className='mt-n3 pb-3 text-danger'>
          {t('component.rhMapping.warningSubscription', {
            count: subscriptionQuery?.data.level.options.rh.categorieLimit,
          })}
        </p>
      )}
      {haveNoMatchMapping && (
        <p className='mt-n3 pb-3 text-danger'>
          {t('component.rhMapping.warningMessage')}
        </p>
      )}

      <div className=' px-5 ps-9 mb-3 mapping-row'>
        <span>{t('component.rhMapping.col_label1')}</span>
        <span className='text-center'>
          {t('component.rhMapping.col_label2')}
        </span>
        <span className='text-center'>
          {t('component.rhMapping.col_label3')}
        </span>
      </div>
      <Scrollbar
        style={{
          maxHeight: '50vh',
          paddingRight: '2rem',
          marginRight: '-2rem',
        }}
      >
        <div>
          {sortedColumns.map((entry, id) => {
            const isSelected = selectedCategories.includes(entry[0])

            return (
              <div className='d-flex align-items-center mb-3 gap-3' key={id}>
                <div className='mx-3'>
                  <Form.Check
                    onChange={() => onChangeSelectedCategories(entry[0])}
                    checked={isSelected}
                    disabled={
                      sortedCategories.includes(entry[0]) ||
                      (!canSelectMore && !isSelected)
                    }
                  />
                </div>

                <div
                  className={`content-row flex-1 ${isSelected && 'bg-opacity'}`}
                >
                  <span>
                    <span id={`label-${id}`}>{entry[0]}</span>
                  </span>
                  <span className='text-center'>
                    <div className='d-flex flex-column px-5'>
                      <span className='text-truncate'>{entry[1][0]}</span>
                      <span className='text-truncate opacity-50'>
                        {entry[1][1]}
                      </span>
                      <span className='text-truncate opacity-25'>
                        {entry[1][2]}
                      </span>
                    </div>
                  </span>
                  <div>
                    <div className='d-flex align-items-center gap-2'>
                      {mapping[entry[0]] ? (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className='text-success'
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          className='text-danger'
                        />
                      )}

                      <Select
                        onChange={(e) => handleFilters(entry[0], e)}
                        className='select w-100'
                        value={
                          mapping[entry[0]] || {
                            value: 'none',
                            label: t('common.none'),
                          }
                        }
                        options={availOptions}
                      />
                    </div>
                  </div>
                  {!isSelected && !canSelectMore && (
                    <div className='disabled-row' />
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </Scrollbar>
    </div>
  )
}

function RHMapping({
  columns,
  options,
  mapping,
  showErrorMessage,
  onShowErrorMessage,
  selectedCategories,
  onChangeSelectedCategories,
  onFilter,
}) {
  const { t } = useTranslation()

  return (
    <>
      {showErrorMessage ? (
        <div>
          <p>{t('component.rhMapping.error.text2')}</p>

          <Button
            className='float-end mt-4'
            onClick={() => onShowErrorMessage(false)}
          >
            {t('component.rhMapping.error.text1')}
          </Button>
        </div>
      ) : (
        columns &&
        options && (
          <Mapping
            handleFilters={onFilter}
            columns={columns}
            options={options}
            mapping={mapping}
            showErrorMessage={showErrorMessage}
            onChangeSelectedCategories={onChangeSelectedCategories}
            selectedCategories={selectedCategories}
          />
        )
      )}
    </>
  )
}

export default RHMapping
